import AuthPage from "../AuthPage/AuthPage"
import { Routes, Route } from "react-router-dom"
import { useState, useEffect } from "react"
import { verifUser } from "../../services/server"
import { useNavigate, useLocation } from "react-router-dom"
import LayoutPage from "../LayoutPage/LayoutPage"
import AllServices from "../ServicesPage/AllServices/AllServices"
import CurrService from "../ServicesPage/CurrServices/CurrService"
import CategoryService from "../ServicesPage/CategoryServices/CategoryService"

const App = () => {
	const [user, setUser] = useState(null)
	const [services, setServices] = useState(null)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const isUser = JSON.parse(localStorage.getItem("adm-user"))

		if (isUser) {
			verifUser(isUser).then(data => {
				if (data && data.status) {
					setUser(data.admin)
					setServices(data.services)
				} else {
					navigate("/")
				}
			})
		} else {
			navigate("/")
		}
	}, [navigate, location.pathname])

	useEffect(() => {
		if (services) {
			navigate(location.pathname)
		}
	}, [services, navigate, location.pathname])

	const updateServiceState = (updatedService) => {
		setServices(prevServices => ({
			...prevServices,
			allServices: prevServices.allServices.map(service =>
				service.id === updatedService.id ? updatedService : service
			)
		}))
	}

	const updateCategoryState = (updatedCategory, serviceId) => {
		setServices(prevServices => ({
			...prevServices,
			allServices: prevServices.allServices.map(service =>
				service.id === serviceId
					? {
						...service,
						categories: service.categories.map(category =>
							category.id === updatedCategory.id ? updatedCategory : category
						)
					}
					: service
			)
		}))
	}

	return (
		<Routes>
			<Route path="/" element={<AuthPage setUser={setUser} navigate={navigate} />} />
			<Route path="/services" element={<LayoutPage><AllServices services={services} /></LayoutPage>} />
			<Route path="/services/:serviceId" element={<LayoutPage><CurrService services={services} updateServiceState={updateServiceState} /></LayoutPage>} />
			<Route path="/services/:serviceId/:categoryId" element={<LayoutPage><CategoryService services={services} updateCategoryState={updateCategoryState} /></LayoutPage>} />
		</Routes>
	)
}

export default App