import "./CategoryService.scss"
import { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import { updateCategoryTitle, updateCategoryContent } from "../../../services/server"
import TextEditor from "../../TextEditor/TextEditor"
import Notification from "../../Notification/Notification"

const CategoryService = ({ services, updateCategoryState }) => {

	const [currService, setCurrService] = useState(null)
	const [currCategory, setCurrCategory] = useState(null)
	const [currCategoryContent, setCurrCategoryContent] = useState("")
	const [inpValue, setInpValue] = useState("")
	const [isSaving, setIsSaving] = useState(false)
	const [notification, setNotification] = useState(null)
	const { serviceId, categoryId } = useParams()

	useEffect(() => {
		if (services && serviceId && categoryId) {
			const service = services.allServices.find(el => el.id === serviceId)
			const category = service.categories.find(el => el.id === categoryId)
			setCurrService(service)
			setCurrCategory(category)
			setCurrCategoryContent(category.content)
			setInpValue(category.title)
		}
	}, [serviceId, services, categoryId])

	const showNotification = (message, type) => {
		setNotification({ message, type })
	}

	const handleCategoryTitle = (event) => {
		event.preventDefault()
		setIsSaving(true)

		updateCategoryTitle(serviceId, categoryId, inpValue).then(data => {
			if (data && data.status) {
				const updatedCategory = { ...currCategory, title: inpValue }
				setCurrCategory(updatedCategory)
				updateCategoryState(updatedCategory, serviceId)
				showNotification("Дані оновлено!", "success")
			} else {
				showNotification("Щось пішло не так... Спробуйте пізніше!", "error")
			}
			setIsSaving(false)
		})
	}

	const handleCategoryContent = () => {
		setIsSaving(true)

		updateCategoryContent(serviceId, categoryId, currCategoryContent).then(data => {
			if (data && data.status) {
				const updatedCategory = { ...currCategory, content: currCategoryContent }
				setCurrCategory(updatedCategory)
				updateCategoryState(updatedCategory, serviceId)
				showNotification("Дані оновлено!", "success")
			} else {
				showNotification("Щось пішло не так... Спробуйте пізніше!", "error")
			}
			setIsSaving(false)
		})
	}

	return (
		<section className="category-service">
			<h1><Link to={`/services/${serviceId}`}>&#11013; До усіх категорій</Link></h1>
			<h2>{currService && currService.serviceName}</h2>

			<form onSubmit={handleCategoryTitle}>
				<div className="curr-service__inp">
					<label htmlFor="service-name">Назва категорії</label>
					<input
						type="text"
						id="service-name"
						value={inpValue}
						onChange={(event) => setInpValue(event.target.value)}
					/>
				</div>
				<button className="yellow-btn" disabled={isSaving}>
					{isSaving ? "..." : "Зберегти"}
				</button>
			</form>

			<div className="category-service__editor">
				<h3>Текст категорії</h3>

				{
					currCategory && (
						<TextEditor
							inValue={currCategory.content}
							setContent={setCurrCategoryContent}
							update={handleCategoryContent}
							isSaving={isSaving}
						/>
					)
				}
			</div>
			{notification && (
				<Notification
					message={notification.message}
					type={notification.type}
					onClose={() => setNotification(null)}
					time={5000}
				/>
			)}
		</section>
	)
}

export default CategoryService