import "./Notification.scss"
import { useEffect } from "react"

const Notification = ({ message, type, onClose, time }) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			onClose()
		}, time)

		return () => clearTimeout(timer)
	}, [onClose])

	return (
		<div className={`notification ${type}`}>
			{message}
		</div>
	)
}

export default Notification