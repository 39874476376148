import "./AuthPage.scss"
import { logo } from "../../services/image.js"
import { useState } from "react"
import { verifUser } from "../../services/server.js"

const AuthPage = ({ setUser, navigate }) => {
	const [disableStatusBtn, setDisableStatusBtn] = useState(false)
	const [inps, setInps] = useState({
		log: "",
		pass: ""
	})

	const [msg, setMsg] = useState("")

	const handleSendUser = (event) => {
		event.preventDefault()
		setDisableStatusBtn(true)
		verifUser({ login: inps.log, password: inps.pass }).then(data => {
			if (data && data.status) {
				setUser(data.admin)
				localStorage.setItem("adm-user", JSON.stringify(data.admin))
				navigate("/services")
			} else {
				setMsg("Не вірний логін або пароль")
			}
			setDisableStatusBtn(false)
		})
	}

	return (
		<main id="auth">
			<div className="auth__form">
				<img src={logo} alt="logo" />
				<form onSubmit={handleSendUser}>
					<input required placeholder="Логін" value={inps.log} onChange={(event) => setInps(prev => {
						return {
							...prev,
							log: event.target.value
						}
					})} type="text" />
					<input required placeholder="Пароль" value={inps.pass} onChange={(event) => setInps(prev => {
						return {
							...prev,
							pass: event.target.value
						}
					})} type="password" />
					{
						msg && <p>{msg}</p>
					}
					<button className="yellow-btn" disabled={disableStatusBtn}>
						{
							disableStatusBtn ? "Вхід..." : "Увійти"
						}
					</button>
				</form>
			</div>
		</main>
	)
}

export default AuthPage