import "./Menu.scss"
import { logo } from "../../services/image"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

const Menu = () => {

	const navigate = useNavigate()

	const handleLogout = () => {
		localStorage.removeItem('adm-user')
		navigate('/')
	}

	return (
		<header>
			<img src={logo} alt="logo" />
			<nav>
				<Link to={"/services"} className="nav-link yellow-btn">Послуги</Link>
				<button onClick={handleLogout} className="red-btn">Вийти</button>
			</nav>
		</header>
	)
}

export default Menu