const URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function verifUser(user) {
	try {
		let res = await fetch(URL + "/admin", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"api-key": API_KEY
			},
			body: JSON.stringify(user)
		});
		let data = await res.json();
		return data;
	} catch (error) {
		return error;
	}
}

async function updateServiceName(serviceId, serviceName) {
	try {
		let res = await fetch(`${URL}/services/${serviceId}/name`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": API_KEY
			},
			body: JSON.stringify({ serviceName })
		});
		let data = await res.json();
		return data;
	} catch (error) {
		return error;
	}
}

async function updateCategoryTitle(serviceId, categoryId, title) {
	try {
		let res = await fetch(`${URL}/services/${serviceId}/categories/${categoryId}/title`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": API_KEY
			},
			body: JSON.stringify({ title })
		});
		let data = await res.json();
		return data;
	} catch (error) {
		return error;
	}
}

async function updateCategoryContent(serviceId, categoryId, content) {
	try {
		let res = await fetch(`${URL}/services/${serviceId}/categories/${categoryId}/content`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": API_KEY
			},
			body: JSON.stringify({ content })
		});
		let data = await res.json();
		return data;
	} catch (error) {
		return error;
	}
}

export { verifUser, updateCategoryContent, updateCategoryTitle, updateServiceName }