import { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ inValue, setContent, update, isSaving }) => {
	const editorRef = useRef(null);
	const [isContentUpdated, setIsContentUpdated] = useState(false);

	const handleBtn = () => {
		if (editorRef.current) {
			let newContent = editorRef.current.getContent();
			console.log(newContent);
			setContent(newContent);
			setIsContentUpdated(true);
		}
	};

	useEffect(() => {
		if (isContentUpdated) {
			update();
			setIsContentUpdated(false);
		}
	}, [isContentUpdated, update]);

	return (
		<>
			<Editor
				apiKey='ejdtlhp6p7ut493klp7affjr2z92hrbbrhvw97yga0un731o'
				onInit={(_evt, editor) => editorRef.current = editor}
				initialValue={inValue}
				init={{
					height: 600,
					menubar: false,
					plugins: [
						'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
						'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
						'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
					],
					toolbar: 'undo redo | blocks | ' +
						'bold italic forecolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
				}}
			/>
			<button onClick={handleBtn} className="yellow-btn" disabled={isSaving}>
				{isSaving ? "..." : "Зберегти"}
			</button>
		</>
	);
}

export default TextEditor;