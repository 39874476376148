import "./CurrService.scss"
import { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import { updateServiceName } from "../../../services/server"
import Notification from "../../Notification/Notification"

const CurrService = ({ services, updateServiceState }) => {
	const [currService, setCurrService] = useState(null)
	const [inpValue, setInpValue] = useState("")
	const [isSaving, setIsSaving] = useState(false)
	const [notification, setNotification] = useState(null)
	const { serviceId } = useParams()

	useEffect(() => {
		if (services && serviceId) {
			const service = services.allServices.find(el => el.id === serviceId)
			setCurrService(service)
			setInpValue(service.serviceName)
		}
	}, [serviceId, services])

	const handleServiceNameUpdate = (event) => {
		event.preventDefault()
		setIsSaving(true)

		updateServiceName(serviceId, inpValue).then(data => {
			if (data && data.status) {
				const updatedService = { ...currService, serviceName: inpValue }
				updateServiceState(updatedService)
				showNotification("Дані успішно оновлено", "success")
			} else {
				showNotification("Щось пішло не так, спробуйте пізніше", "error")
			}
			setIsSaving(false)
		})
	}

	const showNotification = (message, type) => {
		setNotification({ message, type })
	}

	return (
		<section className="curr-service">
			<h1><Link to="/services">&#11013; До усіх послуг</Link></h1>
			<form onSubmit={handleServiceNameUpdate}>
				<div className="curr-service__inp">
					<label htmlFor="service-name">Назва послуги</label>
					<input
						type="text"
						id="service-name"
						value={inpValue}
						onChange={(event) => setInpValue(event.target.value)}
					/>
				</div>
				<button className="yellow-btn" disabled={isSaving}>
					{isSaving ? "..." : "Зберегти"}
				</button>
			</form>

			<div className="curr-service__categories">
				<h3>Категорії</h3>
				<div className="categories__list">
					{currService
						? currService.categories.map(el => (
							<Link to={`/services/${serviceId}/${el.id}`} className="categories__list-item" key={el.id}>
								{el.title}
							</Link>
						))
						: "Загрузка..."}
				</div>
			</div>

			{notification && (
				<Notification
					message={notification.message}
					type={notification.type}
					onClose={() => setNotification(null)}
					time={5000}
				/>
			)}
		</section>
	)
}

export default CurrService