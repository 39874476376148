import "./LayoutPage.scss"
import Menu from "../Menu/Menu"

const LayoutPage = ({ children }) => {

	return (
		<div className="container">
			<div className="main-wrapper">
				<Menu />
				<main>
					{children}
				</main>
			</div>
		</div>
	)
}

export default LayoutPage