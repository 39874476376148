import "./AllServices.scss"
import { Link } from "react-router-dom"

const AllServices = ({ services }) => {
	return (
		<section className="services">
			<h1>Послуги</h1>

			<div className="services__list">
				{
					services && services.allServices ?
						services.allServices.map(el => {
							return (
								<Link key={el.id} to={`/services/${el.id}`} className="services__list-item">
									{el.serviceName}
								</Link>
							)
						}) : "Загрузка..."
				}
			</div>
		</section >
	)
}

export default AllServices